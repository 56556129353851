<template>
  <section title="">
    <b-card>
      <b-row>
        <vue-confirm-dialog />
          <b-card-body>
            <div style="display: flex; justify-content: space-between;">
              <div class="titulo-pagina">
                Atividade
              </div>
              <b-button
                  variant="outline-success"
                  @click="adicionar"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                Adicionar Atividade
              </b-button>
            </div>
            <b-row class="mb-1">
              <b-col
                md="9"
              />
              <b-col md="3" />
            </b-row>
            <data-table
              :data="data"
              :columns="columns"
              @on-table-props-changed="reloadTable"
            >
              <tbody
                slot="body"
                slot-scope="{
                  // eslint-disable-next-line vue/no-template-shadow
                  data }"
              >
                <tr
                  v-for="item in data"
                  :key="item.id"
                >
                  <td
                    v-for="column in columns"
                    :key="column.name"
                  >
                    <data-table-cell
                      :value="item"
                      :name="column.name"
                      :meta="column.meta"
                      :comp="column.component"
                      :classes="column.classes"
                    />
                    <slot v-if="column.label === 'Ações'">
                      <b-button
                        v-b-modal.popupEditar
                        variant="info"
                        class="btn-icon rounded-circle"
                        @click="EditarProjeto(item)"
                      >
                        <feather-icon icon="Edit3Icon" />
                      </b-button>
                      <b-button
                        variant="danger"
                        class="btn-icon rounded-circle ml-1"
                        @click="DeletarDados(item)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </slot>
                    <slot v-if="column.label === 'status'">
                      <b-badge
                        :variant="NomeStatus(item).cor"
                      >
                        {{ NomeStatus(item).msg }}
                      </b-badge>
                    </slot>
                  </td>
                </tr>
              </tbody>
            </data-table>
          </b-card-body>
      </b-row>
    </b-card>
    <b-modal
      id="popupEditar"
      title="Editar Atividade"
      hide-footer
      centered
      size="lg"
    >
      <b-row>
        <b-col>
          <b-form-group

            label="Nome :"
            label-for="nome"
          >
            <b-form-input
              id="nome"
              v-model="nome"
              placeholder="Nome"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Status:"
            label-for="status"
          >
            <v-select
              v-model="status"
              label="title"
              :options="opcoesstatus"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group

            label="Descrição :"
            label-for="descricao"
          >
            <b-form-input
              id="descricao"
              v-model="descricao"
              placeholder="Descrição"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            block
            @click="SalvarCliente()"
          >
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>

import { BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'IndexAtividadeLista',
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      // ------------- DADOS TABELA -------- \\
      url: 'admin/atividade_categoria',
      data: {},
      tableProps: {
        search: '',
        length: 10,
        column: 'id',
        dir: 'asc',
      },
      columns: [
        {
          label: 'nome',
          name: 'nome',
          orderable: true,
        },
        {
          label: 'disciplina',
          name: 'disciplina.nome',
          orderable: true,
        },
        {
          label: 'status',
          orderable: true,
        },
        {
          label: 'Ações',
          orderable: false,
        },
      ],
      // -------------------------- Dados Banco -------------------------- \\
      nome: null,
      descricao: null,
      status: null,
      opcoesstatus: [
        { id: 1, title: 'Ativo' },
        { id: 2, title: 'Inativo' },
      ],
    }
  },
  created() {
    this.$loading(true)
    this.BuscarDados(this.url)
  },
  methods: {
    adicionar() {
      this.$router.push({
        name: 'adm-atividade-adicionar',
      })
    },
    reloadTable(tableProps) {
      this.mostraPopUp = false
      this.BuscarDados(this.url, tableProps)
    },
    async BuscarDados(url = this.url, options = this.tableProps) {
      await this.$http.get(url, { params: options }).then(resp => {
        this.data = resp.data
      })
      this.$loading(false)
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'NOTIFICAÇÃO',
        variant: cor,
        solid: true,
      })
    },
    LimparCampos() {
      this.id = null
      this.nome = null
      this.descricao = null
      this.status = null
    },
    SalvarCliente() {
      const obj = {
        nome: this.nome,
        descricao: this.descricao,
        status: this.status.id,
      }
      // --------------------- FUNÇÃO DE EDITAR ---------------------------- //
      this.$http.put(`admin/atividade_categoria/${this.id}`, obj).then(resp => {
        if (resp.data.success) {
          this.Notificacao('success', resp.data.success)
        }
        if (resp.data.error) {
          this.Notificacao('danger', resp.data.error)
        }
        this.$bvModal.hide('popupEditar')
        this.reloadTable()
      })
    },
    NomeStatus(item) {
      if (item.status === 2) {
        return { cor: 'secondary', msg: 'Inativo' }
      }
      if (item.status === 1) {
        return { cor: 'success', msg: 'Ativo' }
      }
      return { cor: 'danger', msg: 'Sem status' }
    },
    EditarProjeto(item) {
      localStorage.setItem('editatividade', JSON.stringify(item))
      const atividade = JSON.parse(localStorage.getItem('editatividade'))
      this.id = atividade.id
      this.nome = atividade.nome
      this.descricao = atividade.descricao
      this.status = atividade.status.id
      if (item.status === 1) {
        this.status = { title: 'Ativo', value: 1 }
      }
      if (item.status === 0) {
        this.status = { title: 'Inativo', value: 0 }
      }
    },
    async DeletarDados(item) {
      this.$confirm(
        {
          message: 'Tem certeza que deseja Excluir?',
          button: {
            no: 'Não',
            yes: 'Sim',
          },
          /**
             * Callback Function
             * @param {Boolean} confirm
             */
          callback: async confirm => {
            if (confirm) {
              await this.$http.delete(`admin/atividade_categoria/${item.id}`)
              this.Notificacao('success', 'Atividade Removida com sucesso')
              this.reloadTable()
            }
          },
        },
      )
    },
  },
}
</script>

<style scoped>
</style>
